export default function GameFull() {
    return (
        <div className="w-screen h-screen">
            <iframe
                id="game-iframe"
                src="https://monkey-mart.gamestores.fun/"
                width="100%"
                height="100%"
                frameBorder="0"
                title="Snake Game"
                style={{
                    aspectRatio: "16 / 9",
                    objectFit: "cover",
                    borderRadius: "8px",
                    maxWidth: "fit-content",
                    border: "2px solid #05D369",
                }}
            ></iframe>
        </div>
    )
}