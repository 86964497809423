import { useEffect } from "react";

export default function TermOfUse() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'Terms of Use';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Review the Terms of Use for Monkey Mart to understand our policies on site access, content usage, and user responsibilities.");
        }
        return () => {
            document.title = 'Monkey Mart - Play Monkey Mart Game Online Unblocked!';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Monkey Mart Game unblocked at school or work! Discover the best collection of unblocked games, perfect for quick breaks and endless fun anytime, anywhere!');
            }
        };
    }, []);

    return (
        <div className="content-body 2xl:min-h-[calc(100vh-18vh)] text-[#383838] mb-10">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">
                        Terms of Use for Monkey Mart
                    </h1>
                    <br />
                    <span class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Thank you for visiting Monkey Mart. By accessing or using our website, you agree to the following Terms of Use. Please review these terms carefully as they govern your rights and responsibilities while using our services.
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        1. Acceptance of Terms
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        By using Monkey Mart, you accept and agree to abide by these Terms of Use, as well as any additional terms and conditions that may apply.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        2. Site Access and Use
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        You agree to:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px'
                        }}>
                            <li>
                                Use the site only for lawful purposes.
                            </li>
                            <li>
                                Refrain from sharing, posting, or distributing content that violates the rights of others or is offensive, defamatory, or otherwise unlawful.
                            </li>
                            <li>
                                Not engage in activities that could harm or disrupt the site.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        3. Intellectual Property
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        All content on Monkey Mart, including text, graphics, logos, and media, is owned by Monkey Mart or our licensors. Unauthorized use, copying, or distribution of any content is prohibited.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        4. User-Generated Content
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        You may contribute content, such as comments or reviews, on our website. By posting content, you grant Monkey Mart a non-exclusive, royalty-free license to use, display, and distribute this content. We reserve the right to remove or modify any user-generated content deemed inappropriate.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        5. Disclaimers and Limitation of Liability
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        While we strive to provide accurate and up-to-date information, Monkey Mart makes no guarantees as to the completeness or accuracy of content on our site. Monkey Mart is not liable for any losses or damages arising from your use of our website.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        6. Termination of Use
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We reserve the right to terminate or suspend access to Monkey Mart without prior notice if you violate any of these Terms of Use.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        7. Changes to Terms
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We may update these Terms of Use periodically to reflect changes in our practices. We encourage users to review this page regularly to stay informed.
                    </div>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        For inquiries regarding these Terms, please contact us at <a href="mailto:support@snakegame.games" className=" text-blue-500">{`[support@snakegame.games]`}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}