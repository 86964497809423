import { useEffect } from "react";

export default function Policy() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'Privacy Policy';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Review the Terms of Use for Monkey Mart to understand our policies on site access, content usage, and user responsibilities.");
        }
        return () => {
            document.title = 'Monkey Mart - Play Monkey Mart Game Online Unblocked!';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Monkey Mart Game unblocked at school or work! Discover the best collection of unblocked games, perfect for quick breaks and endless fun anytime, anywhere!');
            }
        };
    }, []);

    return (
        <div className="content-body mb-10 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">
                        Privacy Policy for Monkey Mart
                    </h1>
                    <br />
                    <span class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Welcome to Monkey Mart. Your privacy is a priority for us. This Privacy Policy outlines how we collect, use, and protect your information while you interact with our site and services. By accessing our website, you agree to the collection and use of your data as described below.
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        1. Information Collection
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We may collect personal information such as your name, email address, and usage data to provide a more personalized experience. This information helps us enhance your interactions with Monkey Mart and improve our services.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        2. Use of Information
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Your data allows us to:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px'
                        }}>
                            <li>
                                Customize content and recommendations based on your preferences.
                            </li>
                            <li>
                                Send you updates, newsletters, or promotional offers if you’ve subscribed.
                            </li>
                            <li>
                                Improve website performance through analytics and user feedback.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        3. Data Security
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We implement robust security measures to protect your personal information from unauthorized access, alteration, or disclosure. Our website employs industry-standard security technologies to keep your data secure.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        4. Third-Party Services
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We may work with third-party providers to enhance our services. These partners may collect information as necessary to perform services on our behalf, but they are obligated to maintain the confidentiality of your data.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        5. Cookies
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Monkey Mart uses cookies to collect data for analytics and website performance improvements. You can disable cookies through your browser settings, but this may impact certain site functionalities.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        6. Your Rights
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        You have the right to:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px'
                        }}>
                            <li>
                                Access, update, or delete your personal information.
                            </li>
                            <li>
                                Opt-out of email communications and subscriptions.
                            </li>
                            <li>
                                Request information on how your data is stored and used.
                            </li>
                            <li>
                                For questions regarding your privacy, please contact us at <a href="mailto:support@snakegame.games" className=" text-blue-500">{`[support@snakegame.games]`}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}