import { useEffect, useState } from "react";
// import Popular from "./popular/page";
import { FaPlay } from "react-icons/fa";

function useMobilePlatform() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobilePlatforms = /android|iphone|ipod|windows phone|blackberry/i;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    setIsMobile(mobilePlatforms.test(userAgent) || isIOS);
  }, []);

  return isMobile;
}

export default function Home() {
  const isMobile = useMobilePlatform();

  const handleFullScreen = () => {
    const iframe = document.getElementById("game-iframe");
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen();
    } else if (iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    }
  };

  const handleClick = () => {
    window.location.href = "#/game";
  };

  return (
    <div className="relative z-50 bg-[#FFEEF1]">
      {!isMobile ?
        <div className="2xl:flex lg:flex flex items-center justify-center 2xl:h-[85vh] lg:h-[75vh] h-[65vh] lg:mx-24 mx-8">
          <iframe
            id="game-iframe"
            src="https://watermelon-drop.gamestores.fun/"
            width="100%"
            height="100%"
            frameBorder="0"
            title="Snake Game"
            style={{
              aspectRatio: "16 / 9",
              objectFit: "cover",
              borderRadius: "8px",
              maxWidth: "fit-content",
              border: "2px solid #FF6A84",
            }}
          ></iframe>
        </div>
        :
        <div className="flex flex-col items-center justify-center h-[35vh] lg:mx-24 mx-8 opacity-90 rounded-lg">
          <div className="relative w-full h-full flex flex-col items-center justify-center">
            <div className="absolute inset-0 bg-playnow opacity-50"></div>
            <button className="w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center relative animate-zoom" onClick={handleClick}>
              <FaPlay className="text-black w-6 h-6" />
            </button>
            <a className="poppins-bold font-bold text-[20px] text-white mt-3 relative"
              href="#/game"
              style={{ textShadow: "1px 1px 2px black, -1px -1px 2px black, 1px -1px 2px black, -1px 1px 2px black" }}
            >Play now</a>
          </div>

        </div>
      }
      <div className="flex items-center justify-center">
        <div className="container">
          <div className="2xl:flex lg:flex md:hidden hidden justify-center mt-6 2xl:mb-[40px] lg:mb-[28px] mb-[28px]">
            <button
              onClick={handleFullScreen}
              className="btn-full-screen 2xl:py-6 2xl:px-16 lg:py-3 lg:px-8 md:py-3 md:px-8 px-4 py-2 rounded"
            >
              <span className="lilita-one-regular 2xl:text-4xl lg:text-3xl md:text-3xl text-white">
                Full Screen
              </span>
            </button>
          </div>
          {/* <Popular /> */}

          <div className="text-[#383838] lg:px-20 md:px-9 px-6 2xl:pt-20 lg:pt-20 md:pt-14 content-des 2xl:px-56 2xl:pb-20 md:pb-14 pt-14 pb-14">
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              How to play Watermelon Drop?
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              <ul style={{
                listStyleType: 'disc',
                marginLeft: '30px'
              }}>
                <li>
                  Move your fruit or vegetable with the mouse and click to drop it into the cup!
                </li>
              </ul>
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Who created Watermelon Drop?
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Watermelon Drop is created by yeqwep. Play their other game on Poki: Cats Drop!
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              How can I play Watermelon Drop for free?
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              You can play Watermelon Drop for free on Poki.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Can I play Watermelon Drop on mobile devices and desktop?
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Watermelon Drop can be played on your computer and mobile devices like phones and tablets.
            </div>

            <div className="control flex items-center 2xl:mt-10 lg:mt-10 mt-4 md:mt-10">
              <div className="2xl:pl-8 2xl:py-8 lg:pl-8 lg:py-8 px-8 py-8">
                <h2 className="2xl:text-2xl lg:text-2xl md:text-2xl text-2xl font-bold text-start lilita-one-regular">
                  Control - How to play
                </h2>
                <ul
                  style={{
                    listStyleType: "disc",
                    marginLeft: "30px",
                  }}
                >
                  <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start mt-4 poppins-regular">
                    Run around the market to grab fresh crops and stand next to aisles stack them up for the customers to pick up. You don't need to press any buttons - your character will do all the work as long as you're standing in the right place!
                  </li>
                  <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start poppins-regular">
                    Move - WASD or Arrow keys
                  </li>
                </ul>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}
