import { useEffect } from "react";

export default function DMCA() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'DMCA POLICY';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Monkey Mart respects copyright laws. Learn about our DMCA policy and how to report any content concerns or copyright violations. Protecting rights and ensuring compliance!");
        }
        return () => {
            document.title = 'Monkey Mart - Play Monkey Mart Game Online Unblocked!';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Monkey Mart Game unblocked at school or work! Discover the best collection of unblocked games, perfect for quick breaks and endless fun anytime, anywhere!');
            }
        };
    }, []);

    return (
        <div className="content-body 2xl:min-h-[calc(100vh-14vh)] lg:min-h-[calc(100vh-15vh)] md:min-h-[calc(100vh-10vh)] mb-4 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">
                        DMCA Policy - Monkey Mart Game
                    </h1>
                    <br />
                    <span class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Monkey Mart Game is committed to respecting intellectual property rights and complying with the Digital Millennium Copyright Act (DMCA). If you believe that any content on this site infringes on your copyright, please follow the procedure below.
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Reporting Copyright Infringement
                    </h2>
                    <br />
                    <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px'
                        }}>
                            <li>
                                To submit a copyright infringement notice, please provide the following details:
                            </li>
                            <li>
                                Your contact information – Include your full name, mailing address, phone number, and email.
                            </li>
                            <li>
                                Identification of copyrighted work – Describe the work that you claim has been infringed.
                            </li>
                            <li>
                                Location of infringing material – Provide the exact URL(s) on Monkey Mart Game’s site.
                            </li>
                            <li>
                                Statement of good faith – Confirm that you believe, in good faith, that the use of the content is unauthorized.
                            </li>
                            <li>
                                Statement of accuracy – Confirm that the information provided in your notice is accurate, under penalty of perjury.
                            </li>
                            <li>
                                Signature – Include a physical or electronic signature.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        How to Submit
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Send your DMCA notice to our designated agent at:
                    </div>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        *Email: <a href="mailto:support@snakegame.games" className=" text-blue-500">{`support@snakegame.games`}</a>
                    </div>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Upon receiving a valid DMCA notice, we will promptly investigate and take appropriate action.
                    </div>
                </div>
            </div>
        </div>
    )
}