import React, { useEffect } from "react";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import logo from "./assets/images/logo-header.png";
import About from "./pages/about";
import ContactUs from "./pages/contactUs";
import DMCA from "./pages/dmca";
import Footer from "./pages/footer";
import GameFull from "./pages/game";
import Home from "./pages/home";
import Policy from "./pages/policy";
import TermOfUse from "./pages/termOfUse";

function App() {
  const location = useLocation();
  const currentPath = location.pathname;

  // React.useEffect(() => {
  //   document.title = process.env.REACT_APP_OG_TITLE;

  //   const metaDescription = document.querySelector("meta[property='og:description']");
  //   if (metaDescription) {
  //     metaDescription.setAttribute("content", process.env.REACT_APP_OG_DESCRIPTION);
  //   }

  //   const nameDescription = document.querySelector("meta[name='description']");
  //   if (nameDescription) {
  //     nameDescription.setAttribute("content", process.env.REACT_APP_OG_DESCRIPTION);
  //   }

  //   const metaTwitterDescription = document.querySelector("meta[name='twitter:description']");
  //   if (metaTwitterDescription) {
  //     metaTwitterDescription.setAttribute("content", process.env.REACT_APP_OG_DESCRIPTION);
  //   }

  //   const metaImage = document.querySelector("meta[property='og:image']");
  //   if (metaImage) {
  //     metaImage.setAttribute("content", process.env.REACT_APP_OG_IMAGE);
  //   }

  //   const metaImageSecure = document.querySelector("meta[property='og:image:secure_url']");
  //   if (metaImageSecure) {
  //     metaImageSecure.setAttribute("content", process.env.REACT_APP_OG_IMAGE_SECURE);
  //   }

  //   const canonicalLinks = document.querySelectorAll("meta[property='og:url']");
  //   canonicalLinks.forEach(link => {
  //     link.setAttribute("content", process.env.REACT_APP_LINK_URL);
  //   });
  // }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (!window.location.hash) {
      window.location.href = "/#";
    }
  }, []);
  console.log(`version: ${process.env.REACT_APP_VERSION}`);
  return (
    <div
      className={`
        flex flex-col min-h-screen content-body relative overflow-x-hidden 
        ${currentPath.includes("dmca") ||
          currentPath.includes("about") ||
          currentPath.includes("policy") ||
          currentPath.includes("term-of-use") ||
          currentPath.includes("contact-us")
          ? "overflow-y-hidden"
          : ""
        }`}
    >
      <header className="flex items-center justify-between 2xl:px-60 lg:px-40 md:px-7 px-6 py-4 bg-[#FFEEF1]">
        <Link to="/">
          <img className="2xl:w-[70px] 2xl:h-[70px] lg:w-[70px] lg:h-[70px] md:w-[70px] md:h-[70px] w-[70px] h-[70px]" src={logo} alt="logo" />
        </Link>
        <Link to="/">
          <span className="text-[32px] lilita-one-regular text-[#FF6A84]">Walter Melon</span>
        </Link>
      </header>
      <Routes className="App ">
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/term-of-use" element={<TermOfUse />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/dmca" element={<DMCA />} />
        <Route path="/game" element={<GameFull />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
